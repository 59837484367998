import languageSettings from '../constants/language';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import { useEffect, useState } from 'react';

export const getLangShort = (longLang) => {
  return languageSettings.languages.find(
    (language) => language.codeLong === longLang
  ).codeShort;
};

export const useViewport = () => {
  const breakpoint = useBreakpoint();

  if (breakpoint.max) {
    return { size: 'max', index: 5 };
  } else if (breakpoint.xxlarge) {
    return { size: 'xxlarge', index: 4 };
  } else if (breakpoint.xlarge) {
    return { size: 'xlarge', index: 3 };
  } else if (breakpoint.large) {
    return { size: 'large', index: 2 };
  } else if (breakpoint.medium) {
    return { size: 'medium', index: 1 };
  } else {
    return { size: 'small', index: 0 };
  }
};

export const replaceEach = (string, remove, replace) => {
  var re = new RegExp(remove, 'g');
  return string.replace(re, replace);
};

export const useConsent = () => {
  const [consent, setConsent] = useState({});

  useEffect(() => {
    const consentListener = setInterval(() => {
      let cookie = getCookie('legalmonster-cookie-consent');
      if (cookie) {
        cookie = cookie[Object.keys(cookie)[0]].cookieConsentState;

        if (cookie.marketing !== consent.marketing) {
          setConsent(cookie);
        } else if (cookie.analytics !== consent.analytics) {
          setConsent(cookie);
        }
      }
    }, 1000);

    return () => clearInterval(consentListener);
  }, [consent]);

  return consent;
};

export const getCookie = (name) => {
  const cookies = {};
  document.cookie.split(';').forEach((cookie) => {
    cookies[cookie.split('=')[0].replace(' ', '')] = cookie.split('=')[1];
  });

  return cookies[name] ? JSON.parse(decodeURIComponent(cookies[name])) : null;
};

export const removeCookie = (name, path, domain) => {
  document.cookie =
    encodeURIComponent(name) +
    '=; expires=Thu, 01 Jan 1970 00:00:00 GMT' +
    (domain ? '; domain=' + domain : '') +
    (path ? '; path=' + path : '');
};

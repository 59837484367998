export const shades = {
  white: "#fff",
  gray60: "#f2f2f3",
  gray50: "#e2e2e2",
  gray40: "#adb1b6",
  gray30: "#808489",
  gray20: "#4d5258",
  gray10: "#292f36",
  black: "#000",
}

export const colors = {
  alive:{
    steel: "#849FBC",
    teal: "#A7CBC7",
    nude: "#F1ADA2",
    hibiscus: "#C58A8C",
    bronze: "#D89E71",
    gold: "#D4C29C",
    blue: "#0000FF",
    orange: "#ff5200",
    white: "#fff",
    black: "#222222",
    gray:shades.gray40,
  },
  alterna:{
    yellow:"#fff450",
    blue:"#0095da",
    orange:"#d36a26",
    green:"#009048",
    pink:"#d74b9b",
    black:"#231f20",
    gray:"#cdcfd0",
    white: "#fff",
  }
}


import React, { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import styled, { css, createGlobalStyle } from "styled-components"
import {
  media,
  colors,
  MAXWIDTH,
  getGridGap,
  zLevels,
  getColPadding,
  fontFamily,
} from "../../constants"
import Heading from "../typography/Heading"
import { toggleNavMenu } from "../../state/reducers/global"
import { Link } from "gatsby"
import Icon from "../../icon"
import { useViewport } from "../../utils"
import Button from "../../uiKit/Button"

const GlobalStyle = createGlobalStyle`
  body {
    overflow:${props => (props.navMenuToggled ? "hidden" : "visible")};
  }
`

const StyledHeaderWrapper = styled.div`
  height: ${props => `calc(${getGridGap(props.viewport.size)} * 3)`};
  top: ${props => `calc(${getGridGap(props.viewport.size)} / 2)`};
  grid-column-start: outer-xxx-left;
  grid-column-end: outer-xxx-right;
  display: grid;
  align-items: center;
  position: relative;
  z-index: ${zLevels[9]};

  @media ${media.medium} {
    height: ${props => `calc(${getGridGap(props.viewport.size)} * 1.5)`};
  }
`

const StyledLogo = styled.div`
  height: 100%;
  width: 10em;
  @media ${media.medium} {
    width: 8em;
  }
`

const StyledTopWrapper = styled.div`
  position: absolute;
  z-index: ${zLevels[10]};
  width: 100%;
  will-change: opacity;
  a {
    text-decoration: none;
  }

  ${props => {
    if (props.scrolled) {
      return css`
        left: 0;
        top: 0;
        width: 100vw;
        position: fixed;
        background-color: ${props =>
          props.navMenuToggled ? "none" : colors[process.env.THEME].white};
        padding-right: ${props =>
          getColPadding("outer-xxx", props.viewport.size)};
        padding-left: ${props =>
          getColPadding("outer-xxx", props.viewport.size)};
        padding-bottom: ${props =>
          `calc((${getGridGap(props.viewport.size)} * 1) / 2)`};
        padding-top: ${props =>
          `calc((${getGridGap(props.viewport.size)} * 1) / 2)`};
        box-shadow: ${props =>
          props.navMenuToggled
            ? "none"
            : "0px 0px 10px 0px rgba(0, 0, 0, 0.1)"};

        h4 {
          font-size: ${props => (props.navMenuToggled ? "inherit" : "1.1em")};
          color: ${props =>
            props.navMenuToggled
              ? colors[process.env.THEME].white
              : colors[process.env.THEME].black};
        }
      `
    }
  }}

  h4 {
    position: relative;
    cursor: pointer;
  }
`

const StyledContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 100vw;
  height: 100%;
  margin: auto;
  @media ${media.max} {
    max-width: ${MAXWIDTH};
  }
`

const StyledRight = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-gap: 3em;
  align-items: center;
  > div {
    margin-top: -0.2em;
  }
  a {
    background-color: ${props =>
      props.navMenuToggled
        ? "transparent"
        : colors[process.env.THEME][props.theme]};
    border: ${props => (props.navMenuToggled ? "solid 1px black" : "none")};
  }
`
const StyledLeft = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-gap: 3em;
  align-items: center;
`
const StyledPayoff = styled.div`
  width: 7em;
  height: 2em;
`

const Header = ({ isScrolled, cta }) => {
  const dispatch = useDispatch()
  const navMenuToggled = useSelector(state => state.global.navMenuToggled)
  const viewport = useViewport()

  let pathname
  useEffect(() => {
    pathname = window.location.pathname
  })

  return (
    <>
      <GlobalStyle navMenuToggled={navMenuToggled} />
      <StyledHeaderWrapper viewport={viewport}>
        <StyledTopWrapper
          viewport={viewport}
          scrolled={isScrolled}
          navMenuToggled={navMenuToggled}
          onClick={e =>
            e.target.innerHTML === "Menu"
              ? dispatch(toggleNavMenu(!navMenuToggled))
              : null
          }
        >
          <StyledContent>
            <StyledLeft>
              <StyledLogo onClick={() => dispatch(toggleNavMenu(false))}>
                <Link to="/">
                  <Icon icon={`${process.env.THEME}_logo`} theme="black" />
                </Link>
              </StyledLogo>

              {useViewport().index > 1 && (
                <StyledPayoff>
                  <Icon icon="alterna_payoff" />
                </StyledPayoff>
              )}
            </StyledLeft>
            <StyledRight navMenuToggled={navMenuToggled} theme={cta.theme}>
              {pathname !== cta.link.path && cta.label && cta.label !== "" && (
                <Button
                  type="a"
                  url={cta.link?.path}
                  style={process.env.THEME}
                  theme={cta.theme}
                  size={isScrolled ? "small" : "default"}
                >
                  {cta.label}
                </Button>
              )}
              <Heading size="h4" theme="black">
                Menu
              </Heading>
            </StyledRight>
          </StyledContent>
        </StyledTopWrapper>
      </StyledHeaderWrapper>
    </>
  )
}

export default Header

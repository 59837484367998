import React, { useEffect } from "react"
import styled, { css } from "styled-components"
import { fontFamily } from "../../constants/fonts"
import { colors } from "../../constants/colors"
import { useViewport } from "../../utils"

const StyledHeading = styled.h1`
  color: ${props => colors[process.env.THEME][props.theme]};
  font-family: ${fontFamily[process.env.THEME].headingItalic};
  text-align: ${props => props.align};
  line-height: 1em;
  text-transform: uppercase;
  letter-spacing: ${process.env.THEME === "alterna" ? "0.1em" : "initial"};

  ${props => {
    switch (props.size) {
      case "special":
        return css`
          font-size: 6.25em;
          line-height: 0.99em;
          font-family: ${fontFamily[process.env.THEME].special};
          text-transform: none;
        `
      case "h1":
        return css`
          font-size: 5em;
        `
      case "h2":
        return css`
          font-size: 3.75em;
        `
      case "h3":
        return css`
          font-size: 2.5em;
          text-transform: none;
        `
      case "h4":
        return css`
          font-size: 1.87em;
        `
      case "h5":
        return css`
          font-size: 1.25em;
          font-family: ${fontFamily[process.env.THEME].headingBold};
          text-transform: none;
        `
      default:
        return css``
    }
  }}
`

const Heading = ({ size, theme, children, align }) => {
  const breakpoint = useViewport()
  useEffect(() => {
    // render on every breakpoint change
  }, [breakpoint])
  return (
    <StyledHeading
      as={size === "special" ? "h1" : size}
      theme={theme}
      align={align}
      size={size}
    >
      {children}
    </StyledHeading>
  )
}

export default Heading

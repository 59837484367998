import React, { useEffect } from "react"
import styled from "styled-components"
import { media, spacing } from "../../constants"
import Header from "./Header"
import Footer from "./Footer"
import Navigation from "./Navigation"
import Heading from "../typography/Heading"
import { Body } from "../typography"
import { useViewport } from "../../utils"
import Announcement from "./Announcement"
import { setScroll } from "../../state/reducers/global"
import { useDispatch, useSelector } from "react-redux"
import { Grid, Row } from "../grid"

const StyledHeading = styled.div`
  grid-column-start: outer-xxx-left;
  grid-column-end: outer-xxx-right;
  margin-top: ${spacing.medium};
  margin-bottom: ${spacing.medium};
  h1 {
    font-size: 3em;
    text-transform: none;
    @media ${media.medium} {
      font-size: 4em;
    }
    @media ${media.large} {
      font-size: 5em;
    }
  }
  p {
    max-width: 40em;
  }
  @media ${media.medium} {
    grid-column-start: outer-xx-left;
    grid-column-end: outer-xx-right;
  }
`

const Layout = ({
  children,
  data,
  showPageTitle,
  pageTitle,
  pageShortDescription,
  showPageShortDescription,
  announcement,
}) => {
  const dispatch = useDispatch()
  const viewport = useViewport()
  const isScrolled = useSelector(state => state.global.isScrolled)
  const kids = children.props.children
  const cta = {
    link: data.cta_page_link,
    label: data.cta_label,
    theme: data.cta_theme,
  }
  const hasAnnouncement = announcement?.text && announcement.text !== ""

  useEffect(() => {
    window.removeEventListener("scroll", scrollListener)
    window.addEventListener("scroll", scrollListener)

    return () => {
      window.removeEventListener("scroll", scrollListener)
    }
  })

  function scrollListener(event) {
    const scrollVal = event.target.scrollingElement.scrollTop

    if (viewport.index < 5) {
      if (scrollVal > 0 && isScrolled === false) {
        dispatch(setScroll(true))
      } else if (scrollVal <= 0) {
        dispatch(setScroll(false))
      }
    } else {
      if (scrollVal > 75 && isScrolled === false) {
        dispatch(setScroll(true))
      } else if (scrollVal <= 75) {
        dispatch(setScroll(false))
      }
    }
  }

  return (
    <>
      <Grid hasAnnouncement={hasAnnouncement}>
        <Header isScrolled={isScrolled} cta={cta} />
        {hasAnnouncement && (
          <Announcement content={announcement} scrolled={isScrolled} />
        )}

        {showPageTitle && (
          <Row>
            <StyledHeading>
              {pageTitle && (
                <>
                  <Heading size="h1" theme="blue">
                    {pageTitle}
                  </Heading>
                  {showPageShortDescription && (
                    <Body size="big" theme="black">
                      {pageShortDescription}
                    </Body>
                  )}
                </>
              )}
            </StyledHeading>
          </Row>
        )}

        {kids.length >= 0 ? (
          kids.map((block, index) => <Row key={`kid${index}`}>{block}</Row>)
        ) : (
          <>{children}</>
        )}

        <Row>
          <Footer
            cta={cta}
            socials={data.socials}
            linksLeft={data.footer_links_left}
            linksMiddle={data.footer_links_middle}
            linksRight={data.footer_links_right}
          />
        </Row>

        <Navigation
          socials={data.socials}
          linksLeft={data.navigation_links_left}
          linksRight={data.navigation_links_right}
          linksMiddle={data.navigation_links_middle}
        />
      </Grid>
    </>
  )
}

export default Layout

import React from "react"
import styled from "styled-components"
import { colors } from "../../constants/colors"
import Icon from "../../icon"

const StyledWrapper = styled.div`
  display: flex;
  width: ${props => (props.size === "big" ? "2em" : "1.2em")};
  height: ${props => (props.size === "big" ? "1.5em" : "1em")};
  svg {
    width: ${props => (props.size === "big" ? "2em" : "1.2em")};
    height: ${props => (props.size === "big" ? "1.5em" : "1em")};
    margin-right: ${props => (props.size === "big" ? "1.5em" : "1em")};
    g,
    path {
      fill: ${props => colors[process.env.THEME][props.theme]};
    }
  }
`

const Socials = ({ content, theme, size }) => {
  return (
    <StyledWrapper theme={theme} size={size}>
      {Object.keys(content).map(index => (
        <a
          key={index}
          href={content[index].link_url.url}
          target={content[index].link_url.target}
        >
          <Icon icon={content[index].icon} />
        </a>
      ))}
    </StyledWrapper>
  )
}

export default Socials

import React from "react"
import styled from "styled-components"
import { useSelector, useDispatch } from "react-redux"
import { toggleNavMenu } from "../../state/reducers/global"
import { media } from "../../constants/breakpoints"
import { colors } from "../../constants/colors"
import { MAXWIDTH, getGridLayout, zLevels } from "../../constants/grid"
import Heading from "../typography/Heading"
import Socials from "../elements/Socials"
import { Link } from "gatsby"
import { useViewport } from "../../utils"

const StyledWrapper = styled.div`
  position: fixed;
  left: 0;
  top: ${props => (props.show ? "0" : "-100vh")};
  width: 100vw;
  height: 100vh;
  background-color: ${props => colors[process.env.THEME][props.theme]};
  transition: all 0.5s ease-in-out;
  z-index: ${zLevels[8]};
`
const StyledInnerWrapper = styled.div`
  display: grid;
  max-width: ${MAXWIDTH};
  height: 100%;
  margin: auto;
  grid-template-columns: ${props => getGridLayout(props.viewport.size)};
`

const StyledMenuWrapper = styled.div`
  grid-column-start: outer-xxx-left;
  grid-column-end: outer-xxx-right;
  display: grid;
  opacity: ${props => (props.show ? "1" : "0")};
  grid-template-columns: 1fr;
  grid-gap: 3em;
  align-self: center;
  a {
    text-decoration: none;
    cursor: pointer;
  }
  h2 {
    color: ${colors[process.env.THEME].white};
  }

  @media ${media.max} {
    grid-column-start: edge-left;
    grid-column-end: edge-right;
  }
`

const StyledSocialsWrapper = styled.div`
  grid-row-start: 2;
  grid-row-end: 3;
`
const StyledLinksWrapper = styled.div`
  display: grid;
  @media ${media.large} {
    grid-template-columns: max-content max-content;
    grid-gap: 10em;
  }
  @media ${media.xlarge} {
    grid-template-columns: max-content max-content max-content;
  }
`

const StyledColumn = styled.div``

const Navigation = ({ socials, linksLeft, linksRight, linksMiddle }) => {
  const dispatch = useDispatch()
  const navMenuToggled = useSelector(state => state.global.navMenuToggled)
  const viewport = useViewport()
  const links = {
    linksLeft: linksLeft,
    linksMiddle: linksMiddle,
    linksRight: linksRight,
  }

  const renderLink = (link, i) => {
    return (
      <>
        {link.url?._linkType === "Link.document" && (
          <Link
            key={i}
            to={link.url.path}
            onClick={() => dispatch(toggleNavMenu(false))}
          >
            <Heading size="h2">{link.label}</Heading>
          </Link>
        )}

        {link.url?._linkType === "Link.web" && (
          <a key={i} href={link.url.url} target={link.url.target}>
            <Heading size="h2">{link.label}</Heading>
          </a>
        )}
      </>
    )
  }

  return (
    <StyledWrapper show={navMenuToggled} theme="blue">
      <StyledInnerWrapper viewport={viewport}>
        <StyledMenuWrapper show={navMenuToggled}>
          <StyledLinksWrapper>
            <StyledColumn column="left">
              {links.linksLeft.map((link, i) => renderLink(link, i))}
            </StyledColumn>
            <StyledColumn column="middle">
              {links.linksMiddle.map((link, i) => renderLink(link, i))}
              {viewport.index <= 2 && (
                <>{links.linksRight.map((link, i) => renderLink(link, i))}</>
              )}
            </StyledColumn>
            {viewport.index > 2 && (
              <StyledColumn column="right">
                {links.linksRight.map((link, i) => renderLink(link, i))}
              </StyledColumn>
            )}
          </StyledLinksWrapper>

          <StyledSocialsWrapper>
            <Socials theme="white" size="big" content={socials} />
          </StyledSocialsWrapper>
        </StyledMenuWrapper>
      </StyledInnerWrapper>
    </StyledWrapper>
  )
}

export default Navigation

import React from "react"
import styled from "styled-components"
import { fontFamily } from "../../constants/fonts"
import { colors } from "../../constants/colors"

const StyledWrapper = styled.span`
  font-family: ${fontFamily.headingItalic};
  position: relative;
`

const StyledInnerWrapper = styled.span`
  position: relative;
  &:after,
  &:before {
    content: "";
    position: absolute;
    right: 0;
    background-color: ${props => colors[process.env.THEME][props.theme]};
    width: 0.2em;
    height: 0.8em;
    border-radius: 0px;
  }
  &:before {
    bottom: 50%;
    transform: translateX(1.2em) rotate(-45deg);
    transform-origin: 100% 100%;
  }

  &:after {
    top: 50%;
    transform: translateX(1.2em) rotate(45deg);
    transform-origin: 100% 0;
  }
`

const StyledButton = styled.div`
  font-size: 1em;
  color: ${props => colors[process.env.THEME][props.theme]};
  text-decoration: none;
  outline: none;
`

const ButtonAlive = ({ children, url, target, theme, type }) => {
  return (
    <StyledWrapper>
      <StyledInnerWrapper theme={theme}>
        <StyledButton as={type} href={url} target={target} theme={theme}>
          {children}
        </StyledButton>
      </StyledInnerWrapper>
    </StyledWrapper>
  )
}

export default ButtonAlive

export const baseFontSize = {
  small: 11,
  medium: 14,
  large: 16,
  xlarge: 16,
  xxlarge: 18,
  max: 20,
}

export const getFontSize = size => {
  return `${baseFontSize[size]}px`
}

export const fontFamily = {
  alive: {
    headingItalic: "MontserratExtraboldItalic",
    headingBold: "MontserratBold",
    paragraph: "MontserratRegular",
    paragraphItalic: "",
    special: "BenguiatBold",
  },
  alterna: {
    headingItalic: "'Bebas Neue', Arial Narrow, Arial, sans-serif",
    headingBold: "'Bebas Neue', Arial Narrow, Arial, sans-serif",
    paragraph: "'Roboto', Arial, sans-serif",
    paragraphItalic: "",
    special: "'Rock Salt', Brush Script MT",
  },
}

import React from "react"
import styled, { css } from "styled-components"
import { fontFamily } from "../../constants/fonts"
import { colors } from "../../constants/colors"

const StyledBody = styled.p`
  color: ${props => colors[process.env.THEME][props.theme]};
  font-family: ${fontFamily[process.env.THEME].paragraph};
  text-align: ${props =>
    props.align === "right"
      ? "right"
      : props.align === "center"
      ? "center"
      : "left"};
  line-height: 1.5em;

  font-style: ${props => (props.italic ? "italic" : "none")};

  ${props => {
    switch (props.size) {
      case "big":
        return css`
          font-size: 1.5em;
        `
      case "medium":
        return css`
          font-size: 1.2em;
        `
      case "small":
        return css`
          font-size: 0.6em;
        `
      default:
        return css`
          font-size: 1em;
        `
    }
  }};
`

const Body = ({ size, theme, children, align, italic }) => {
  return (
    <StyledBody size={size} theme={theme} align={align} italic={italic}>
      {children}
    </StyledBody>
  )
}

export default Body

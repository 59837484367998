import React from "react"
import { colors } from "../constants/colors"
import styled from "styled-components"
import * as Icons from "./IconList"

const StyledIcon = styled.div`
  display: grid;
  width: ${props =>
    props.size === "small"
      ? "3.5em"
      : props.size === "medium"
      ? "5em"
      : props.size === "big"
      ? "7.5em"
      : "100%"};
  height: ${props =>
    props.size === "small"
      ? "3.5em"
      : props.size === "medium"
      ? "5em"
      : props.size === "big"
      ? "7.5em"
      : "100%"};

  svg {
    align-self: center;
    justify-self: center;
    path,
    polygon {
      /* fill: ${colors[process.env.THEME].black}; */
    }
    .a {
      fill: ${props => props.shadow};
    }
    .b {
      fill: none;
    }
    .c {
      fill: ${props => props.secondary};
    }
    .d {
      fill: ${props => props.primary};
    }
    .noFill {
      fill: none;
    }
    .keepOriginal {
      //
    }
  }
`

export const Icon = ({ icon, theme, size }) => {
  const Icon = Icons[icon]

  let primary = colors[process.env.THEME][theme]
  let secondary = colors[process.env.THEME]["blue"]
  const shadow = "rgba(0,0,0,0.1)"

  if (process.env.THEME === "alterna") {
    switch (theme) {
      case "yellow":
        primary = colors.alterna.yellow
        secondary = colors.alterna.orange
        break
      case "blue":
        primary = colors.alterna.blue
        secondary = colors.alterna.pink
        break
      case "orange":
        primary = colors.alterna.orange
        secondary = colors.alterna.blue
        break
      case "green":
        primary = colors.alterna.green
        secondary = colors.alterna.black
        break
      case "pink":
        primary = colors.alterna.pink
        secondary = colors.alterna.black
        break
      default:
        primary = colors.alterna.orange
        secondary = colors.alterna.black
        break
    }
  } else if (process.env.THEME === "alive") {
    switch (theme) {
      case "black":
        primary = colors.alive.black
        secondary = colors.alive.black
        break
      case "white":
        primary = colors.alive[theme]
        secondary = colors.alive[theme]
        break
      default:
        primary = colors.alive.black
        secondary = colors.alive.black
    }
  }

  return (
    <StyledIcon
      size={size}
      primary={primary}
      secondary={secondary}
      shadow={shadow}
    >
      {Icon && <Icon />}
    </StyledIcon>
  )
}

import React from "react"
import styled, { createGlobalStyle } from "styled-components"
import {
  media,
  MAXWIDTH,
  getGridGap,
  getGridLayout,
  fontFamily,
} from "../../constants"

const GlobalStyle = createGlobalStyle`
  * {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
  }

  body {
    overflow-x: hidden;
    font-size:11px!important;
    font-family:${fontFamily[process.env.THEME].paragraph};
    @media ${media.medium} {
      font-size: 14px!important;
    }
    @media ${media.large} {
      font-size: 16px!important; 
    }
    @media ${media.xxlarge} {
      font-size: 18px!important;
    }
    @media ${media.max} {
      font-size: 20px!important;
    }
  }
  
  p, li, span, label{
    font-family: ${fontFamily[process.env.THEME].paragraph};
  }
  
  h1,h2,h3,h4,h5,h6{
    font-family: ${fontFamily[process.env.THEME].headingItalic};
  }
`

const StyledWrapper = styled.div`
  display: grid;
  width: 100vw;
  max-width: ${MAXWIDTH};
  margin: auto;
  margin-top: ${props => (props.hasAnnouncement ? "2.5em" : "0")};
  grid-row-gap: ${getGridGap("small")};
  grid-template-columns: ${getGridLayout("small")};

  @media ${media.medium} {
    grid-template-columns: ${getGridLayout("medium")};
    grid-row-gap: ${getGridGap("medium")};
  }
  @media ${media.large} {
    grid-template-columns: ${getGridLayout("large")};
    grid-row-gap: ${getGridGap("large")};
  }
  @media ${media.xlarge} {
    grid-template-columns: ${getGridLayout("xlarge")};
    grid-row-gap: ${getGridGap("xlarge")};
  }
  @media ${media.xxlarge} {
    grid-template-columns: ${getGridLayout("xxlarge")};
    grid-row-gap: ${getGridGap("xxlarge")};
  }
  @media ${media.max} {
    grid-template-columns: ${getGridLayout("max")};
    grid-row-gap: ${getGridGap("max")};
  }
`

const Grid = ({ children, hasAnnouncement }) => {
  return (
    <>
      <GlobalStyle />
      <StyledWrapper hasAnnouncement={hasAnnouncement}>
        {children}
      </StyledWrapper>
    </>
  )
}

export default Grid

import React from "react"
import ButtonAlterna from "./ButtonAlterna"
import ButtonAlive from "./ButtonAlive"

const Button = props => {
  const { style } = props
  return (
    <>
      {style === "alterna" && (
        <ButtonAlterna {...props}>{props.children}</ButtonAlterna>
      )}
      {style === "alive" && (
        <ButtonAlive {...props}>{props.children}</ButtonAlive>
      )}
    </>
  )
}

export default Button

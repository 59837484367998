import React, { useEffect } from "react"
import styled from "styled-components"
// import FrikirkeNetLogo from "../../../assets/frikirkenet_logo.svg"
// import MosaikLogo from "../../../assets/mosaik_logo.svg"
import { shades } from "../../constants/colors"
import { Body } from "../typography"
import Socials from "../elements/Socials"
import { Link } from "gatsby"
import Icon from "../../icon"
import Button from "../../uiKit/Button"
import { media } from "../../constants/breakpoints"
import { useViewport } from "../../utils"

const StyledWrapper = styled.div`
  grid-column-start: outer-xxx-left;
  grid-column-end: outer-xxx-right;
  margin-bottom: 4.4em;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 2em;
  @media ${media.medium} {
    margin-left: auto;
    margin-right: auto;
    max-width: 50em;
    grid-template-columns: 1fr max-content 1fr;
    grid-gap: 7em;
  }
`
const StyledMenuWrapper = styled.div`
  grid-gap: 3.2em;
  color: ${shades.gray40};
  vertical-align: top;
  a {
    text-decoration: none;
    color: ${shades.gray40};
  }
  p {
    line-height: 2em;
    @media ${media.medium} {
      font-size: 0.65em;
      line-height: 2em;
    }
  }
  > div {
    vertical-align: top;
    display: inline-block;
    margin-right: 3.2em;
    &:last-child {
      margin-right: 0;
    }
  }
`
const StyledSocialsWrapper = styled.div`
  display: flex;
  margin-top: 1em;
`
const StyledColumn = styled.div`
  display: flex;
  flex-direction: column;
  /* gap: 1em; */
`

const StyledLogo = styled.div`
  width: 12em;
  @media ${media.medium} {
    width: 8em;
  }
`

const Footer = ({ socials, cta, linksLeft, linksRight, linksMiddle }) => {
  const links = {
    linksLeft: linksLeft,
    linksMiddle: linksMiddle,
    linksRight: linksRight,
  }

  let pathname

  useEffect(() => {
    pathname = window.location.pathname
  })

  return (
    <StyledWrapper>
      <StyledColumn>
        <Link to="/">
          <StyledLogo>
            <Icon icon={`${process.env.THEME}_logo`} theme="gray" />
          </StyledLogo>
        </Link>
        <StyledSocialsWrapper>
          <Socials
            content={socials}
            size={useViewport().index >= 1 ? "normal" : "big"}
            theme="gray"
          />
        </StyledSocialsWrapper>
      </StyledColumn>

      <StyledColumn>
        <StyledMenuWrapper>
          {Object.keys(links).map((column, i) => (
            <div key={`column${i}`}>
              {links[column].map((link, i) => {
                return (
                  <>
                    {link?.url?._linkType === "Link.document" && (
                      <Link key={`footerLink${i}`} to={link.url?.path}>
                        <Body>{link.label}</Body>
                      </Link>
                    )}

                    {link?.url?._linkType === "Link.web" && (
                      <a
                        key={`footerLink${i}`}
                        href={link.url.url}
                        target={link.url?.target}
                      >
                        <Body>{link.label}</Body>
                      </a>
                    )}
                  </>
                )
              })}
            </div>
          ))}
        </StyledMenuWrapper>
      </StyledColumn>

      <StyledColumn>
        {useViewport().index >= 1 &&
          cta.label &&
          cta.label !== "" &&
          pathname !== cta.link.path && (
            <div>
              <Button
                type={"a"}
                url={cta.link?.path}
                theme="gray"
                style={process.env.THEME}
              >
                {cta.label}
              </Button>
            </div>
          )}
      </StyledColumn>
    </StyledWrapper>
  )
}

export default Footer

import React from 'react';
import styled from 'styled-components';
import { Body } from '../typography';
import { colors, zLevels, media } from '../../constants';

const StyledWrapper = styled.div`
  position: fixed;
  left: 0;
  top: ${(props) => (props.scrolled ? '2.5em' : '0')};
  width: 100vw;
  height: ${(props) => (props.scrolled ? '3.5em' : '2.5em')};
  background-color: ${(props) => props.theme};
  z-index: ${zLevels[7]};
  transition: top 0.2s ease-in-out;
  @media ${media.medium} {
    top: ${(props) => (props.scrolled ? '3em' : '0')};
  }
  @media ${media.large} {
    top: ${(props) => (props.scrolled ? '3.3em' : '0')};
  }
  @media ${media.xxlarge} {
    top: ${(props) => (props.scrolled ? '4em' : '0')};
  }
  @media ${media.max} {
    top: ${(props) => (props.scrolled ? '4.2em' : '0')};
  }
`;
const StyledBanner = styled.a`
  margin: auto;
  width: max-content;
  height: 100%;
  display: grid;
  grid-template-columns: 1fr 2em;
  align-items: center;
  background-color: ${(props) => colors[process.env.THEME][props.theme]};
  text-decoration: none;
  font-weight: 500;
  color: ${colors[process.env.THEME].black};
`;
const StyledClose = styled.div`
  height: 1em;
  width: 1em;
  position: relative;
  &:after {
    content: '';
    height: 1em;
    border-left: 2px solid black;
    position: absolute;
    transform: rotate(45deg);
    left: 28px;
  }
  &:before {
    content: '';
    height: 1em;
    border-left: 2px solid black;
    position: absolute;
    transform: rotate(-45deg);
    left: 28px;
  }
`;

const Announcement = ({ content, scrolled }) => {
  return (
    <StyledWrapper scrolled={scrolled} theme={content.theme}>
      <StyledBanner href={content.url} target={content.target || '_self'}>
        <Body>{content.text}</Body>
        <StyledClose />
      </StyledBanner>
    </StyledWrapper>
  );
};

export default Announcement;

export const colRelation = {
  max: 5,
  xxlarge: 4.4,
  xlarge: 3.3,
  large: 3.3,
  medium: 2.8,
  small: 2,
}

export const MINHEIGHT = "40em"
export const MAXWIDTH = "1710px"

export const spacing = {
  small: "1em",
  medium: "3em",
  large: "5em",
  xlarge: "8em",
}

export const getColSize = size => {
  return `${colRelation[size]}em`
}

export const getGridGap = size => {
  return `${colRelation[size] / 2}em`
}

export const getColPadding = (col, size) => {
  switch (col) {
    case "edge":
      return `${colRelation[size] * 0}em`
    case "outer-xxx":
      return `${colRelation[size] * 1}em`
    case "outer-xx":
      return `${colRelation[size] * 2}em`
    case "outer-x":
      return `${colRelation[size] * 3}em`
    case "inner":
      return `${colRelation[size] * 4}em`
    default:
      return ``
  }
}

export const getGridLayout = size => {
  return `
    [edge-left] 
    ${getColSize(size)} 
    [outer-xxx-left] 
    ${getColSize(size)}
    [outer-xx-left]
    ${getColSize(size)}
    [outer-x-left]
    ${getColSize(size)}
    [inner-left] 
    1fr 
    [inner-right] 
    ${getColSize(size)}
    [outer-x-right] 
    ${getColSize(size)}
    [outer-xx-right]
    ${getColSize(size)} 
    [outer-xxx-right] 
    ${getColSize(size)}
    [edge-right]`
}

export const zLevels = {
  1: 100,
  2: 200,
  3: 300,
  4: 400,
  5: 500,
  6: 600,
  7: 700,
  8: 800,
  9: 900,
  10: 1000,
}

import React from "react"
import styled from "styled-components"
import { media, getGridLayout } from "../../constants"

const StyledWrapper = styled.div`
  display: grid;
  grid-column-start: 1;
  grid-column-end: 10;
  grid-template-columns: ${getGridLayout("small")};
  @media ${media.medium} {
    grid-template-columns: ${getGridLayout("medium")};
  }
  @media ${media.large} {
    grid-template-columns: ${getGridLayout("large")};
  }
  @media ${media.xlarge} {
    grid-template-columns: ${getGridLayout("xlarge")};
  }
  @media ${media.xxlarge} {
    grid-template-columns: ${getGridLayout("xxlarge")};
  }
  @media ${media.max} {
    grid-template-columns: ${getGridLayout("max")};
  }
`

const Row = ({ children }) => {
  return <StyledWrapper>{children}</StyledWrapper>
}

export default Row

import React from "react"
import styled, { css } from "styled-components"
import { fontFamily, media, colors } from "../../constants"

const StyledWrapper = styled.div`
  margin: ${props => (props.center ? "auto" : "0")};
  width: max-content;
`

const StyledButton = styled.div`
  display: block;
  border: none;
  color: ${props =>
    props.theme === "yellow"
      ? colors[process.env.THEME].black
      : colors[process.env.THEME].white};
  text-decoration: none;
  outline: none;
  font-family: ${fontFamily.alterna.headingItalic};
  /* position: relative; */
  background-color: ${props => colors[process.env.THEME][props.theme]};
  transition: all ease-in-out 0.2s;
  letter-spacing: 0.1em;
  cursor: pointer;
  padding: 0.8em;
  padding-left: 2.5em;
  padding-right: 2.5em;
  &:hover {
    transform: scale(1.02);
  }

  ${props => {
    switch (props.size) {
      case "big":
        return css`
          font-size: 1.5em;

          @media ${media.medium} {
            font-size: 1em;
          }
        `
      case "small":
        return css`
          font-size: 0.7em;
        `
      default:
        return css`
          font-size: 0.9em;
        `
    }
  }}
`

const ButtonAlterna = ({
  children,
  url,
  target,
  theme,
  type,
  center,
  size,
}) => {
  return (
    <StyledWrapper center={center}>
      <StyledButton
        as={type}
        theme={theme}
        href={url}
        target={target}
        size={size}
      >
        {children}
      </StyledButton>
    </StyledWrapper>
  )
}

export default ButtonAlterna

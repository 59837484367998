export const breakpoints = {
  MAX: 1900,
  XXLARGE: 1600,
  XLARGE: 1300,
  LARGE: 1000,
  MEDIUM: 750,
}

export const media = {
  medium: `(min-width: ${breakpoints.MEDIUM}px)`,
  large: `(min-width: ${breakpoints.LARGE}px)`,
  xlarge: `(min-width: ${breakpoints.XLARGE}px)`,
  xxlarge: `(min-width: ${breakpoints.XXLARGE}px)`,
  max: `(min-width: ${breakpoints.MAX}px)`,
}
